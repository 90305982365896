import React from 'react'
import styled from '@emotion/styled'
import LBContactInfo from '../components/LBContactInfo'

const Nav = styled.nav`
transform: ${props => (props.open ? 'translate(0)' : 'translate(100%)')};
`
class LBNavigation extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props)
    this.state = {
      isLocalOpen: false,
      isExternalOpen: false,
      isHeaderVisible: false,
    }
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount(){
    this._isMounted = true;
    this.headerVisible();
  }
	  
	componentWillUnmount() {
		this._isMounted = false;
	}

  handleClose(isOpen) {
    if (!isOpen) {
      this.setState({
        isLocalOpen: false,
        isExternalOpen: false
      })
      console.log("isExternalOpen")
      console.log(this.state.isExternalOpen)
    }
  }

  handleOpen(isOpen, e) {
    e.preventDefault();
    if (isOpen) {
      this.setState({
        isLocalOpen: true,
        isExternalOpen: false
      })
    }
  }

  headerVisible() {				
		const trigger = document.querySelector('.home-container .home-bg');

		let options = {
			rootMargin: '-35px',
			threshold: 0
		  };

		let intersectionCallback = (entries) => { 
			entries.forEach(entry => {
				if(entry.intersectionRatio < 0.1 && !entry.isIntersecting) {
					if (this._isMounted) {
						this.setState({isHeaderVisible:true})
					}
				}else{
					if (this._isMounted) {
						this.setState({isHeaderVisible:false})
					}
				}
			});

    };
    if (this.props.isHome) {
      const observer = new IntersectionObserver(intersectionCallback, options)
      observer.observe(trigger)
    }
	}

  render() {
    return (
      <>
      { this.props.isHome ?
      <div className="home-nav">
        <a href="#" onClick={(e) => this.handleOpen(true, e)}>menu</a>
      </div>
      : null}
      <LBHeader
        isVisible={this.props.isHome ? this.state.isHeaderVisible : true} 
        isHome={this.props.isHome} 
        onHandleOpen={this.handleOpen} />

      <Nav className="site-nav" open={this.state.isLocalOpen} >
        <a href="#" className="close-button" onClick={() => this.handleClose(false)}>Close</a>
        <div className="nav-container">
          <ul className="main-nav">
            <li><a className="nav-home" href="/">Home</a></li>
            <li className="nav-border-yellow"><a className="nav-profilo" href="/su-di-me">Su di me</a></li>
            <li className="nav-border-blue"><a className="nav-articoli" href="/articoli/lista">Articoli</a></li>
          </ul>
          <LBContactInfo />
        </div>
        <div className="video-wrapper">
          <video preload="preload" muted="muted" playsInline="" loop="loop" autoPlay="autoplay">
            <source src="/video/video-metro-lb.mp4" />
          </video>
        </div>
      </Nav>
      </>
    )
  }
}

export default LBNavigation


function LBHeader ({isVisible, onHandleOpen}) {

  return (
    <header className={ isVisible ? "site-header _visible" : "site-header"}>
      <div className="row no-gutters header-wrapper">
        <div className="header-left col-4"></div>
        <div className="header-centr col-4">Luca Belli | site</div>
        <div className="header-right col-4">
          <a href="#" className="burger-menu" onClick={(e) => onHandleOpen(true, e)}>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
    </header>
  )
}
